import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Switch
} from 'react-router-dom'
import {
  Row, Col, Button, Spin
} from 'antd'
import { MessageOutlined, CloseOutlined } from '@ant-design/icons'
import queryString from 'query-string'
import SectionTitle from '../../components/SectionTitle'
import Stepper from '../../components/Stepper'
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes'
import ErrorBoundary from '../../components/ErrorBoundary'
import {
  RedirectWithQuery,
  formatterAmountToNumber,
  isNumber
} from '../../utils/misc'
import i18n from '../../utils/i18n'
import {
  nextStep,
  previousStep,
  changeLoanAmount as actionChangeLoanAmount,
  changeMonthyPaymet as actionChangeMonthyPaymet
} from '../../store/actions/simulation'

import './style.css'

const stepTitles = {
  1: 'title.stepTwo',
  2: 'title.stepThree',
  3: 'title.stepFour',
  4: 'title.stepFive'
}

class Simulation extends Component {
  state = {
    chatbotIsHidden: true
  }

  componentDidMount () {
    const stepNode = document.getElementById('steps')
    if (stepNode && stepNode.classList.length > 0) {
      stepNode.classList.remove('ant-steps-label-horizontal')
    }

    const { location: { search } } = window
    const {
      loanAmount: queryLoanAmount,
      monthlyPayment: queryMonthlyPayment
    } = queryString.parse(search)
    const { actions: { changeLoanAmount, changeMonthyPaymet } } = this.props
    const loanAmount = formatterAmountToNumber(queryLoanAmount)
    const monthlyPayment = formatterAmountToNumber(queryMonthlyPayment)
    if (loanAmount && isNumber(loanAmount)) changeLoanAmount(loanAmount)
    if (monthlyPayment && isNumber(monthlyPayment)) changeMonthyPaymet(monthlyPayment)
  }

  handleClick = (e) => {
    const {
      chatbotIsHidden
    } = this.state
    this.setState({
      chatbotIsHidden: !chatbotIsHidden
    })
  }

  render () {
    const {
      currentStep,
      routes
    } = this.props
    const { chatbotIsHidden } = this.state
    return (
      <>
        <Row type='flex' justify='center' className='headerContainer'>
          {currentStep === 0 ? (
            <>
              <Col xs={16} sm={24} md={8} lg={8}>
                <SectionTitle currentStep={currentStep} title={i18n.t('title.stepOne')} style={{ position: 'relative' }} />
              </Col>
              <Col xs={8} sm={10} md={6} lg={6} className='headerImage'>
                <img src='../img/people_cf.svg' alt='Person' />
              </Col>
            </>
          ) : (
            <>
              <Col xs={0} sm={0} md={5} lg={2} className='headerImageStep'>
                <img src='../img/people_step.svg' alt='Person' />
              </Col>
              <Col xs={0} sm={0} md={20} lg={17} className='headerTitleStep'>
                <SectionTitle currentStep={currentStep} title={i18n.t(stepTitles[currentStep])} style={{ position: 'relative' }} />
              </Col>
            </>
          )}
        </Row>
        <Row type='flex' justify='center' >
          <Col xs={24} sm={24} md={20} lg={20}>
            <Stepper currentStep={currentStep} routes={routes} />
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col xs={24} sm={24} md={20} lg={20} className='container-step'>
            <ErrorBoundary>
              <Suspense fallback={<Spin />}>
                <Switch>
                  <RedirectWithQuery exact from='/simulation' to='/simulation/one' />
                  {routes.map((route, i) => (
                    <RouteWithSubRoutes key={`simulation-subroute-${i}`} {...route} /> // eslint-disable-line
                  ))}
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </Col>
        </Row>
        <div
          className='chatbot-container-div'
          style={{
            display: 'block',
            transition: 'right 0.3s ease 0s',
            position: 'fixed',
            bottom: '30px',
            right: '30px',
            borderRadius: '2px',
            overflow: 'hidden'
          }}
        >
          <div
            style={{ display: chatbotIsHidden ? 'none' : 'inherit' }}
          >
            <iframe
              title='chatbot'
              width='100%'
              height='430'
              src='https://console.dialogflow.com/api-client/demo/embedded/f0b09082-c1a1-4f1e-9f28-354849290bca'
            />
          </div>
          { chatbotIsHidden
            ? (
              <Button
                shape='circle'
                size='large'
                className='chatbotButton'
                onClick={this.handleClick}
              >
                <MessageOutlined />
              </Button>
            ) : (
              <Button
                shape='circle'
                size='large'
                className='chatbotButton'
                onClick={this.handleClick}
              >
                <CloseOutlined />
              </Button>
            )}
        </div>
      </>
    )
  }
}

function mapStateToProps (state) {
  return {
    requestPending: state.simulation.requestPending,
    currentStep: state.simulation.currentStep
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      nextStep,
      previousStep,
      changeLoanAmount: actionChangeLoanAmount,
      changeMonthyPaymet: actionChangeMonthyPaymet
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Simulation)
