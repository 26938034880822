import React, { Component, Suspense } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Switch
} from 'react-router-dom'
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes'
import ErrorBoundary from '../../components/ErrorBoundary'

import './style.css'

class Admin extends Component {
  render () {
    const {
      routes
    } = this.props
    return (
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={`admin-subroute-${i}`} {...route} /> // eslint-disable-line
            ))}
          </Switch>
        </Suspense>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
