/* eslint-disable */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Row,
  Col,
  Alert,
  Form,
  Input,
  Button
} from 'antd'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  loginUserAccess as actionLoginUserAccess
} from '../../store/actions/auth'
import IconLogin from './img/icon_login.svg'
import Loading from '../../components/Loading'
import getToken from '../../utils/recaptcha'
import i18n from '../../utils/i18n'

import './style.css'

class UserContinueOperation extends Component {
  state = {
    loading: false
  }

  onSubmit = async (values) => {
    this.setState({ loading: true })
    const {
      loginUserAccess,
      googleReCaptchaProps: { executeRecaptcha }
    } = this.props
    const recaptchaToken = await getToken(executeRecaptcha, 'user_login')
    loginUserAccess(values, recaptchaToken)
    this.setState({ loading: false })
  }

  render() {
    const {
      loading
    } = this.state

    const {
      authenticating,
      errorMessage
    } = this.props
    return (
      <Loading size='large' spinning={authenticating}>
        <div className='loginCard'>
          <Row justify='center' style={{ display: 'grid' }}>
            <h2 style={{ color: '#4D8DFF', fontWeight: '400', fontSize: '1.5rem' }}>
              <img src={IconLogin} style={{ width: '10%' }} />
              {i18n.t('userLogin.loginTitle')}
            </h2>
            <h5>{i18n.t('userLogin.loginSubTitle')}</h5>
          </Row>
          {errorMessage && (<Row display='flex' justify='center'>
            <Col>
              <Alert
                message="Error"
                description={i18n.t(errorMessage)}
                type="error"
                showIcon
              />
            </Col>
          </Row>)}
          <Row display='flex' justify='center' className='FormContinue'>
            <Col>
              <Form onFinish={this.onSubmit} layout='vertical'>
                <Form.Item
                  name='rut'
                  label={i18n.t('ID')}
                  style={{ textAlign: 'left' }}
                  normalize={value => value.toUpperCase()}
                  rules={[{
                    required: true,
                    message: i18n.t('formValidationRutRequired')
                  }]}
                >
                  <Input
                    size='large'
                    style={{ maxWidth: '30rem' }}
                  />
                </Form.Item>

                <Form.Item
                  name='password'
                  label={i18n.t('temporaryPassword')}
                  style={{ textAlign: 'left' }}
                  max={2}
                  rules={[{
                    required: true,
                    message: i18n.t('formValidationRequired')
                  }, {
                    min: 1,
                    max: 20,
                    message: i18n.t('temporaryPasswordValidationMaxMin')
                  }]}
                >
                  <Input
                    size='large'
                    type='password'
                    style={{ maxWidth: '30rem' }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type='primary'
                    className='app-button continue-button'
                    htmlType='submit'
                    style={{ margin: 'auto' }}
                    loading={loading}
                  >
                    {i18n.t('logIn')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Loading>
    )
  }
}

const mapStateToProps = state => ({
  authenticating: state.auth.authenticating,
  isAuthenticated: state.auth.isAuthenticated,
  errorMessage: state.auth.errorMessage
})

const mapDispatchToProps = dispatch => bindActionCreators({
  loginUserAccess: actionLoginUserAccess
}, dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(withGoogleReCaptcha(UserContinueOperation))
