import React from 'react'
import { Layout, Button } from 'antd'
import KretixLogo from './img/kretix_brand.svg'
import './style.css'
import 'antd/dist/antd.css'

const { Header, Footer, Content } = Layout

export default (props) => {
  const { children } = props
  return (
    <div className='App'>
      <Layout className='ant-layout'>
        <Header style={{
          background: '#ffffff', display: 'flex', padding: '.8rem', height: '95px', width: '83%', margin: 'auto'
        }}
        >
          <Button type='link' href='https://kretix.com'>
            <img src={KretixLogo} alt='Logo' width='70%' />
          </Button>
        </Header>
        <Content className='app-container'>
          { children }
        </Content>
        <Footer style={{ backgroundColor: '#ffffff', display: 'flex', justifyContent: 'flex-end' }}>
          {/* <img src='../img/kretix-logo@2x.png' alt='Logo' /> */}
        </Footer>
      </Layout>
    </div>
  )
}
