import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {
  Router, Switch
} from 'react-router-dom'

import HttpsRedirect from 'react-https-redirect'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import RouteWithSubRoutes from './components/RouteWithSubRoutes'
import history from './utils/history'
import { RedirectWithQuery } from './utils/misc'
import { routes } from './routes'
import './style.css'
import { unregister } from './serviceWorker'
import configureStore from './store/configStore'
import './utils/moment'

export const store = configureStore()

const {
  NODE_ENV,
  REACT_APP_GA_ID,
  REACT_APP_GTM_ID,
  REACT_APP_RECAPTCHA_KEY
} = process.env
// Google Analytics
ReactGA.initialize(REACT_APP_GA_ID, {
  debug: NODE_ENV === 'development'
})
// Google Tag Manager
const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <HttpsRedirect>
    <GoogleReCaptchaProvider
      reCaptchaKey={REACT_APP_RECAPTCHA_KEY}
      language='es'
    >
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key='simulation-route' {...route} />
            ))}
            <RedirectWithQuery exact from='/' to='/simulation' />
          </Switch>
        </Router>
      </Provider>
    </GoogleReCaptchaProvider>
  </HttpsRedirect>,
  document.getElementById('root')
)
unregister()

export default store
