import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Layout, Button, Menu, Avatar, Spin
} from 'antd'
import KretixLogo from './img/kretix_brand.svg'
import { logoutAndRedirect } from '../../store/actions/auth'
import './style.css'
import 'antd/dist/antd.css'
import i18n from '../../utils/i18n'

const { Header, Footer, Content } = Layout
const { SubMenu } = Menu

class AdminLayout extends Component {
  onLogout = () => {
    const {
      logout
    } = this.props
    logout('/admin')
  }

  render () {
    const {
      children,
      authenticating,
      isAuthenticated,
      username
    } = this.props
    return (
      <div className='App'>
        <Layout className='ant-layout'>
          <Header style={{
            background: '#ffffff', display: 'flex', padding: '.8rem', height: '95px', width: '83%', margin: 'auto'
          }}
          >
            <Button type='link' href='/'>
              <img src={KretixLogo} alt='Logo' width='70%' />
            </Button>
            <Menu mode='horizontal' className='UserNav'>
              {
                authenticating && <Spin />
              }
              {
                isAuthenticated && !authenticating
                  ? (
                    <SubMenu title={(
                      <div>
                        <Avatar>{username.charAt(0).toUpperCase()}</Avatar>
                        <span style={{ marginLeft: '15px' }}>{username}</span>
                      </div>
                    )}
                    >
                      <Menu.Item key='logout' onClick={this.onLogout}>{i18n.t('logOut')}</Menu.Item>
                    </SubMenu>
                  )
                  : null
              }

            </Menu>
          </Header>
          <Content className='app-container'>
            { children }
          </Content>
          <Footer style={{ backgroundColor: '#ffffff', display: 'flex', justifyContent: 'flex-end' }}>
            {/* <img src='../img/kretix-logo@2x.png' alt='Logo' /> */}
          </Footer>
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticating: state.auth.authenticating,
  isAuthenticated: state.auth.isAuthenticated,
  username: state.auth.username
})

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAndRedirect
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout)
