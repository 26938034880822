import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Login from '../Login'
// import NotFound from '../../components/NotFound'
import {
  checkAuthUser as actionCheckAuthUser
} from '../../store/actions/auth'

export default function (ComposedComponent, ...allowedUserRoles) {
  class Authentication extends Component {
    componentDidMount () {
      const {
        checkAuthUser,
        match: {
          url
        }
      } = this.props
      checkAuthUser(url)
    }

    componentDidUpdate () {
      const {
        checkAuthUser,
        match: {
          url
        }
      } = this.props
      checkAuthUser(url)
    }

    render () {
      const { isAuthenticated, roles } = this.props
      return (
        <>
          {isAuthenticated /* eslint-disable-line */
            ? (
              (allowedUserRoles.length === 0 || roles.some(r => allowedUserRoles.includes(r)))
                ? <ComposedComponent {...this.props} />
                : <h1>Not Found</h1>
            )
            : <Login />
          }
        </>
      )
    }
  }
  const mapStateToProps = ({ auth }) => ({
    isAuthenticated: auth.isAuthenticated,
    roles: auth.roles,
    token: auth.token
  })
  const mapDispatchToProps = dispatch => (
    bindActionCreators({
      checkAuthUser: actionCheckAuthUser
    }, dispatch)
  )
  return connect(mapStateToProps, mapDispatchToProps)(Authentication)
}
