export const FETCH_LOANFLOW_REQUEST = 'FETCH_LOANFLOW_REQUEST'
export const FETCH_LOANFLOW_SUCCESS = 'FETCH_LOANFLOW_SUCCESS'
export const FETCH_LOANFLOW_ERROR = 'FETCH_LOANFLOW_ERROR'
export const VALIDATE_STEP_REQUEST = 'VALIDATE_STEP_REQUEST'
export const VALIDATE_STEP_SUCCESS = 'VALIDATE_STEP_SUCCESS'
export const VALIDATE_STEP_ERROR = 'VALIDATE_STEP_ERROR'

export const FECTH_REQUEST_NEW_USER = 'FECTH_REQUEST_NEW_USER'
export const FECTH_REQUEST_NEW_USER_SIMULATION = 'FECTH_REQUEST_NEW_USER_SIMULATION'
export const FECTH_REQUEST_NEW_USER_SIMULATION_SUCCESS = 'FECTH_REQUEST_NEW_USER_SIMULATION_SUCCESS'
export const FECTH_REQUEST_NEW_USER_SUCCESS = 'FECTH_REQUEST_NEW_USER_SUCCESS'
export const FECTH_REQUEST_NEW_USER_ERROR = 'FECTH_REQUEST_NEW_USER_ERROR'

export const MANAGE_LOAN_SAVE_SIMULATION = 'MANAGE_LOAN_SAVE_SIMULATION'
