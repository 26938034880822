import moment from 'moment'
import { createReducer } from '../../utils/misc'

import {
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTH_USER_ERROR,
  UNAUTH_USER
} from '../constants/auth'

const initialState = {
  isAuthenticated: false,
  authenticating: false,
  token: '',
  refreshToken: '',
  loginTimestamp: null,
  errorMessage: '',
  userId: null,
  username: '',
  permissions: []
}

export default createReducer(initialState, {
  [AUTH_USER_SUCCESS]: (state, payload) => Object.assign({}, state, {
    isAuthenticated: true,
    token: payload.token,
    username: payload.username,
    refreshToken: payload.refreshToken,
    userId: payload.userId,
    permissions: payload.permissions,
    loginTimestamp: moment(),
    error: null,
    authenticating: false
  }),
  [AUTH_USER]: state => Object.assign({}, state, {
    authenticating: true
  }),
  [AUTH_USER_ERROR]: (state, payload) => Object.assign({}, state, {
    errorMessage: payload,
    authenticating: false
  }),
  [UNAUTH_USER]: state => Object.assign({}, state, {
    isAuthenticated: false,
    authenticating: false,
    token: '',
    username: '',
    refreshToken: '',
    error: null,
    loginTimestamp: null
  })
})
