import decoded from 'jwt-decode'
import {
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTH_USER_ERROR,
  UNAUTH_USER
} from '../constants/auth'
import {
  reqTokenValid,
  reqLogin,
  reqLoginUserAccess
} from '../api/auth'
import { parseJSON, pushToUrl } from '../../utils/misc'

export function login (googleCode, recaptchaToken) {
  return (dispatch) => {
    dispatch({
      type: AUTH_USER
    })
    reqLogin({ code: googleCode, recaptchaToken })
      .then(parseJSON)
      .then((response) => {
        dispatch(loginUserSuccess(response))
        pushToUrl({ path: '/admin/dashboard' })
      })
      .catch((err) => {
        console.error(err) // eslint-disable-line
        dispatch({
          type: AUTH_USER_ERROR,
          payload: 'Código de autorización erróneo.'
        })
      })
  }
}

export function loginUserAccess (values, recaptchaToken) {
  return (dispatch) => {
    dispatch({
      type: AUTH_USER
    })
    reqLoginUserAccess({ ...values, recaptchaToken })
      .then(parseJSON)
      .then((response) => {
        dispatch(loginUserSuccess(response))
        const { loanFlowId } = response
        pushToUrl({ path: `/user/documents/upload/${loanFlowId}` })
      })
      .catch((err) => {
        console.error(err) // eslint-disable-line
        dispatch({
          type: AUTH_USER_ERROR,
          payload: 'incorrentUserLogin'
        })
      })
  }
}

export function loginUserSuccess (params) {
  return (dispatch) => {
    const { token } = params
    const decodedToken = decoded(token)
    localStorage.setItem('token', token) // eslint-disable-line
    const { userId, permissions, username } = decodedToken
    dispatch({
      type: AUTH_USER_SUCCESS,
      payload: {
        userId,
        username,
        permissions,
        token
      }
    })
  }
}

export function isTokenValid (token) {
  return (dispatch) => {
    dispatch({
      type: AUTH_USER
    })
    reqTokenValid(token)
      .then(({ status }) => {
        if (status === 200) {
          dispatch(loginUserSuccess({
            token
          }))
        } else {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.error(err) // eslint-disable-line
        dispatch(logout())
      })
  }
}

export function logout () {
  localStorage.removeItem('token') // eslint-disable-line
  return {
    type: UNAUTH_USER
  }
}

export function logoutAndRedirect (to = '/user') {
  return (dispatch) => {
    dispatch(logout())
    pushToUrl({ path: `${to}/login`, params: null })
  }
}

export function checkAuthUser (url = '/user') {
  return (dispatch, getState) => { // eslint-disable-line
    const { auth } = getState()
    if (!auth.isAuthenticated) {
      const token = localStorage.getItem('token') // eslint-disable-line
      if (!token) {
        if (url.startsWith('/admin')) return dispatch(logoutAndRedirect('/admin'))
        return dispatch(logoutAndRedirect())
      }
      return dispatch(isTokenValid(token))
    }
  }
}
