import { lazy } from 'react'
import SimulationLayout from './components/SimulationLayout'
import AdminLayout from './containers/AdminLayout'
import Simulation from './containers/Simulation' // eslint-disable-line
import Admin from './containers/Admin'
import Authenticated from './containers/Authenticated'
// import NotFound from './components/NotFound'
import UserLoanDocuments from './containers/UserLoanDocuments'
import SimpleLogoLayout from './components/SimpleLogoLayout'
import UserContinueOperation from './containers/UserContinueOperation'
import UserLoanNumberRecovery from './containers/UserLoanNumberRecovery'

const Login = lazy(() => import('./containers/Login'))

const StepOne = lazy(() => import('./containers/StepOne'))
const StepTwo = lazy(() => import('./containers/StepTwo'))
const StepThree = lazy(() => import('./containers/StepThree'))
const StepFour = lazy(() => import('./containers/StepFour'))
const StepFive = lazy(() => import('./containers/StepFive'))

const Dashboard = lazy(() => import('./containers/Dashboard'))
const ManageLoanRequest = lazy(() => import('./containers/ManageLoanRequest'))

const UploadDocument = lazy(() => import('./containers/UploadDocument'))
const UserLoanDocumentsUpload = lazy(() => import('./containers/UserLoanDocumentsUpload'))
const UserLoanDocumentsDone = lazy(() => import('./components/UserLoanDocumentsDone'))

export const routes = [
  {
    path: '/simulation',
    component: Simulation,
    layout: SimulationLayout,
    routes: [
      {
        path: '/simulation/one',
        component: StepOne,
        exact: true,
        stepName: 'StepOne',
        step: 0
      },
      {
        path: '/simulation/two',
        component: StepTwo,
        exact: true,
        stepName: 'StepTwo',
        step: 1
      },
      {
        path: '/simulation/three',
        component: StepThree,
        exact: true,
        stepName: 'StepThree',
        step: 2
      },
      {
        path: '/simulation/four',
        component: StepFour,
        exact: true,
        stepName: 'StepFour',
        step: 3
      },
      {
        path: '/simulation/five',
        component: StepFive,
        exact: true,
        stepName: 'StepFive',
        step: 4
      }
    ]
  },
  {
    path: '/admin',
    component: Admin,
    layout: AdminLayout,
    routes: [
      {
        path: '/admin/login',
        component: Login,
        exact: true
      },
      {
        path: '/admin/dashboard',
        component: Authenticated(Dashboard),
        exact: true
      },
      {
        path: '/admin/loan/:id',
        component: Authenticated(ManageLoanRequest),
        exact: true
      },
      {
        path: '/admin/documents',
        component: Authenticated(UploadDocument),
        exact: true
      }
    ]
  },
  {
    path: '/user/documents/:loanflowId',
    component: Authenticated(UserLoanDocuments),
    layout: SimulationLayout,
    routes: [
      {
        path: '/user/documents/upload/:loanflowId',
        component: Authenticated(UserLoanDocumentsUpload),
        exact: true
      },
      {
        path: '/user/documents/done',
        component: Authenticated(UserLoanDocumentsDone),
        exact: true
      }
    ]
  },
  {
    path: '/user/login',
    component: UserContinueOperation,
    layout: SimpleLogoLayout,
    exact: true
  },
  {
    path: '/user/loan_number_recovery',
    component: UserLoanNumberRecovery,
    layout: SimpleLogoLayout,
    exact: true
  }
]

export const simulationSteps = {
  0: {
    route: '/simulation/one'
  },
  1: {
    route: '/simulation/two'
  },
  2: {
    route: '/simulation/three'
  },
  3: {
    route: '/simulation/four'
  },
  4: {
    route: '/simulation/five'
  }
}

export default routes
