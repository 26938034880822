
function getToken(recaptchaFunction, action) {
  if(process.env.NODE_ENV !== 'production') {
    return new Promise((resolve, reject) => {
      resolve('localhostNullToken')
    })
  }
  return recaptchaFunction(action)
}

export default getToken
