import React, { Component } from 'react'
import { Steps } from 'antd'
import { pushToUrl } from '../../utils/misc'

const { Step } = Steps

class Stepper extends Component {
  getStepsLength = () => {
    const {
      routes
    } = this.props
    return routes.length
  }

  render () {
    const { currentStep, routes } = this.props
    const stepsLength = this.getStepsLength()
    const navSteps = routes.map((route, i) => (
      <Step
        key={i} // eslint-disable-line
        onClick={() => (i < currentStep && pushToUrl({ path: routes[i].path }))}
        style={{ cursor: 'pointer' }}
      />
    ))
    return (
      currentStep < stepsLength &&
        (
          <Steps
            id='steps'
            size='small'
            current={currentStep}
            direction='horizontal'
            style={{
              textAlign: 'initial',
              margin: '0 auto'
            }}
          >
            { navSteps }
          </Steps>
        )
    )
  }
}

export default Stepper
