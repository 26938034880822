import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import GoogleLogin from 'react-google-login'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Loading from '../../components/Loading'
import {
  login as actionLogin
} from '../../store/actions/auth'
import getToken from '../../utils/recaptcha'
import './style.css'

class Login extends Component {
  handleSuccess = async ({ code }) => {
    const {
      login,
      googleReCaptchaProps: { executeRecaptcha }
    } = this.props
    const recaptchaToken = await getToken(executeRecaptcha, 'auth_login')
    login(code, recaptchaToken)
  }

  handleError = (response) => {
    console.error(response) // eslint-disable-line
  }

  render () {
    const {
      REACT_APP_OAUTH_CLIENT_ID,
      REACT_APP_LOGIN_REDIRECT_URI
    } = process.env
    const {
      authenticating,
      isAuthenticated
    } = this.props
    return (
      <Loading size='large' spinning={authenticating}>
        {
          isAuthenticated
            ? <h1>Login Success</h1>
            : (
              <GoogleLogin
                clientId={REACT_APP_OAUTH_CLIENT_ID}
                onSuccess={this.handleSuccess}
                onFailure={this.handleError}
                responseType='code'
                redirectUri={REACT_APP_LOGIN_REDIRECT_URI}
              />
            )
        }
      </Loading>
    )
  }
}

const mapStateToProps = state => ({
  authenticating: state.auth.authenticating,
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = dispatch => bindActionCreators({
  login: actionLogin
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withGoogleReCaptcha(Login))
