/* eslint-disable */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Row,
  Col,
  Form,
  Input,
  Button
} from 'antd'
import i18n from '../../utils/i18n'

import './style.css'

class UserLoanNumberRecovery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  onSubmit = async (values) => {
    this.setState({ loading: true })
    // const {

    // } = this.props
    // const recaptchaToken = await getToken(executeRecaptcha, 'simulation_personal_data')
    // await sendData(values, recaptchaToken)
    // await nextStep(currentStep, 'five')
    // this.setState({ loading: false })
  }

  render () {
    const {
      loading
    } = this.state
    return (
      <>
        <Row display='flex' justify='center'>
          <h2>Introduce tu email</h2>
        </Row>
        <Row display='flex' justify='center'>
          <h3>Para reenviar tu número de solicitud</h3>
        </Row>
        <Row display='flex' justify='center' className='FormContinue'>
          <Col>
            <Form onFinish={this.onSubmit} layout='vertical'>
              <Form.Item
                name='email'
                label={i18n.t('formEmail')}
                style={{ textAlign: 'left' }}
                rules={[{
                  type: 'email',
                  message: i18n.t('formValidationEmail')
                }, {
                  required: true,
                  message: i18n.t('formValidationRequired')
                }]}
              >
                <Input
                  size='large'
                  placeholder='p.fernandez@gmail.com'
                  style={{ maxWidth: '30rem' }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  className='app-button continue-button'
                  htmlType='submit'
                  style={{ margin: 'auto' }}
                  loading={loading}
                >
                  {i18n.t('continueSubmit')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {

  }, dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(UserLoanNumberRecovery)
