import i18next from 'i18next'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/es-us'
// Add more as required

const getLanguage = () => i18next.language || window.localStorage.i18nextLng

moment.locale(getLanguage())

export default moment
