import axios from 'axios'

const {
  REACT_APP_API_URL
} = process.env

export default () => {
  const config = {
    baseURL: REACT_APP_API_URL,
    crossDomain: true
  }
  const instance = axios.create(config)
  instance.interceptors.response.use(response => response, (error) => {
    if (error.response) {
      console.error(error)
    }
    return Promise.reject(error)
  })
  return instance
}

export const tokenConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`
  }
})
