import React from 'react'
import { Alert } from 'antd'
import i18n from '../../utils/i18n'
import './style.css'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Alert className='import-alert-error' message={i18n.t('errorBoundary')} type='error' />
    }

    return this.props.children
  }
}
