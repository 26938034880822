import React from 'react'
import { Col } from 'antd'
import './SectionTitle.css'

class SectionTitle extends React.Component {
  componentDidMount() {
    const stepNode = document.getElementById('steps')
    if (stepNode && stepNode.classList.length > 0) {
      stepNode.classList.remove('ant-steps-label-horizontal')
    }
  }

  render() {
    const {
      title
    } = this.props
    return (
      <Col xs={24} sm={24} md={20} lg={20}>
        <h2 className='titleApp'>
          {title}
        </h2>
      </Col>
    )
  }
}

export default SectionTitle
