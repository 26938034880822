import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import simulation from './simulation'
import dashboard from './dashboard'
import user from './user'

const rootReducer = history => combineReducers({
  auth,
  simulation,
  dashboard,
  user,
  router: connectRouter(history)
})

export default rootReducer
