import _ from 'lodash'
import { createReducer, getByIdMap } from '../../utils/misc'
import {
  FECTH_REQUEST_DASHBOARD,
  FECTH_REQUEST_DASHBOARD_SUCCESS,
  FECTH_REQUEST_DASHBOARD_ERROR,
  FECTH_REQUEST_DASHBOARD_SUMMARY,
  FECTH_REQUEST_DASHBOARD_SUMMARY_SUCCESS,
  FECTH_REQUEST_DASHBOARD_SUMMARY_ERROR,
  FECTH_REQUEST_DASHBOARD_LOANFLOW,
  FECTH_REQUEST_DASHBOARD_LOANFLOW_SUCCESS,
  FECTH_REQUEST_DASHBOARD_LOANFLOW_ERROR
} from '../constants/dashboard'
import {
  FETCH_LOANFLOW_REQUEST,
  FETCH_LOANFLOW_SUCCESS,
  FETCH_LOANFLOW_ERROR,
  VALIDATE_STEP_REQUEST,
  VALIDATE_STEP_SUCCESS,
  VALIDATE_STEP_ERROR,
  FECTH_REQUEST_NEW_USER,
  FECTH_REQUEST_NEW_USER_SIMULATION,
  FECTH_REQUEST_NEW_USER_SIMULATION_SUCCESS,
  FECTH_REQUEST_NEW_USER_SUCCESS,
  FECTH_REQUEST_NEW_USER_ERROR,
  MANAGE_LOAN_SAVE_SIMULATION
} from '../constants/loanflowManagement'

const initialState = {
  data: {},
  total: 0,
  page: 1,
  summary: {
    simulationsToday: 0,
    simulationsMonthly: 0,
    simulationsTotal: 0,
    simulationsOk: 0
  },
  requestPending: true,
  requestPendingSummary: true,
  requestPendingLoanFlow: false,
  requestPendingSimulation: false,
  errorsMsg: [],
  creditOfferFlag: false,
  requestPendingNewUserSimulation: false
}

export default createReducer(initialState, {
  [FECTH_REQUEST_DASHBOARD]: state => ({
    ...state,
    requestPending: true
  }),
  [FECTH_REQUEST_DASHBOARD_SUCCESS]: (state, payload) => ({
    ...state,
    data: getByIdMap(payload.documents),
    total: payload.total,
    page: payload.page,
    requestPending: false
  }),
  [FECTH_REQUEST_DASHBOARD_ERROR]: (state, payload) => ({
    ...state,
    data: [],
    requestPending: false,
    errorsMsg: payload
  }),
  [FECTH_REQUEST_DASHBOARD_SUMMARY]: state => ({
    ...state,
    requestPendingSummary: true
  }),
  [FECTH_REQUEST_DASHBOARD_SUMMARY_SUCCESS]: (state, payload) => ({
    ...state,
    summary: payload,
    requestPendingSummary: false
  }),
  [FECTH_REQUEST_DASHBOARD_SUMMARY_ERROR]: (state, payload) => ({
    ...state,
    requestPendingSummary: false,
    errorsMsg: payload
  }),
  [FECTH_REQUEST_DASHBOARD_LOANFLOW]: state => ({
    ...state,
    requestPendingLoanFlow: true
  }),
  [FECTH_REQUEST_DASHBOARD_LOANFLOW_SUCCESS]: (state, payload) => ({
    ...state,
    requestPendingLoanFlow: false
  }),
  [FECTH_REQUEST_DASHBOARD_LOANFLOW_ERROR]: (state, payload) => ({
    ...state,
    requestPendingLoanFlow: false,
    errorsMsgLoanFlow: payload
  }),
  [FETCH_LOANFLOW_REQUEST]: state => ({
    ...state,
    requestPendingLoanFlow: true
  }),
  [FETCH_LOANFLOW_SUCCESS]: (state, payload) => ({
    ...state,
    data: {
      ...state.data,
      [payload._id]: payload // eslint-disable-line
    },
    requestPendingLoanFlow: false
  }),
  [FETCH_LOANFLOW_ERROR]: (state, payload) => ({
    ...state,
    requestPendingLoanFlow: false
  }),
  //
  [VALIDATE_STEP_REQUEST]: state => ({
    ...state,
    requestPendingLoanFlow: true
  }),
  [VALIDATE_STEP_SUCCESS]: (state, payload) => ({
    ...state,
    data: {
      ...state.data,
      [payload._id]: payload // eslint-disable-line
    },
    requestPendingLoanFlow: false
  }),
  [VALIDATE_STEP_ERROR]: (state, payload) => ({
    ...state,
    requestPendingLoanFlow: false
  }),
  [MANAGE_LOAN_SAVE_SIMULATION]: (state, payload) => {
    const newSteps = [...state.data[payload.loanId].template.steps]
    const stepIndex = _.findIndex(newSteps, s => s._id === payload.stepId) // eslint-disable-line
    newSteps[stepIndex].data = payload.data
    return {
      ...state,
      data: {
        ...state.data,
        [payload.loanId]: {
          ...state.data[payload.loanId],
          template: {
            ...state.data[payload.loanId].template,
            steps: newSteps
          }
        } // eslint-disable-line
      }
    }
  },
  [FECTH_REQUEST_NEW_USER]: state => ({
    ...state,
    requestPendingNewUserSimulation: true
  }),
  [FECTH_REQUEST_NEW_USER_SIMULATION]: state => ({
    ...state,
    requestPendingNewUserSimulation: true
  }),
  [FECTH_REQUEST_NEW_USER_SIMULATION_SUCCESS]: state => ({
    ...state,
    requestPendingNewUserSimulation: false
  }),
  [FECTH_REQUEST_NEW_USER_SUCCESS]: state => ({
    ...state,
    requestPendingNewUserSimulation: false
  }),
  [FECTH_REQUEST_NEW_USER_ERROR]: (state, payload) => ({
    ...state,
    requestPendingNewUserSimulation: false,
    errorsMsgLoanFlow: payload
  })
})
