/* eslint-disable */
import React, { Component, Suspense } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Switch
} from 'react-router-dom'
import ErrorBoundary from '../../components/ErrorBoundary'
import {
  RedirectWithQuery
} from '../../utils/misc'
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes'
import { Spin } from 'antd'

import './style.css'

class UserLoanDocuments extends Component {
  render () {
    const {
      routes
    } = this.props
    return (
      <ErrorBoundary>
        <Suspense fallback={<Spin />}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={`user-documents-subroute-${i}`} {...route} /> // eslint-disable-line
              ))}
            <RedirectWithQuery exact from='/user/documents' to='/user/documents/upload' />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {

  }, dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(UserLoanDocuments)
