import client from './apiClient'
import { getLanguageOfBrowser } from '../../utils/misc'

export function fecthGetFinance (params) {
  return client().post(`/simulation`, { ...params })
}

export function fecthSendData (params) {
  return client().post(`/user/demo-us/simulation-data`, { ...params }, {
    headers: {
      'Content-Language': getLanguageOfBrowser(),
    }
  })
}
