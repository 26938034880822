import client, { tokenConfig } from './apiClient'

export function reqLogin (params) {
  return client().post('/auth/login', params, {
    headers: {
      Accept: 'application/json'
    }
  })
}

export function reqTokenValid (token) {
  return client().get('/auth/is-token-valid', tokenConfig(token))
}

export function reqLoginUserAccess (params) {
  return client().post('/user/demo-us/login', params, {
    headers: {
      Accept: 'application/json'
    }
  })
}
