import React from 'react'
import { Route } from 'react-router-dom'

export default function RouteWithSubRoutes (route) {
  return (
    <Route
      key={`route-${route.path}`}
      exact={route.exact}
      path={route.path}
      render={props => (
        route.layout
          ? <route.layout>
            <route.component {...props} routes={route.routes}/>
          </route.layout>
          : <route.component {...props} routes={route.routes} step={route.step}/>
      )}
    />
  )
}
