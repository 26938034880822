import { createReducer } from '../../utils/misc'

import {
  CHANGE_LOAN_AMOUNT,
  CHANGE_MONTHLY_PAYMENT,
  CHANGE_PROTECTION_INSURANCE,
  FECTH_REQUEST_GET_FINANCE,
  FECTH_REQUEST_GET_FINANCE_SUCCESS,
  FECTH_REQUEST_GET_FINANCE_ERROR,
  FECTH_REQUEST_SEND_DATA,
  FECTH_REQUEST_SEND_DATA_SUCCESS,
  FECTH_REQUEST_SEND_DATA_ERROR,
  NEXT_STEP,
  SET_STEP
} from '../constants/simulation'

import {
  loanAmount, protectionInsurance
} from '../../loanConfig.json'

const initialState = {
  currentStep: 0,
  loanAmount,
  monthlyPayment: null,
  result: {},
  requestPendingGetFinance: true,
  requestPendingSendData: false,
  protectionInsurance,
  errorsMsg: []
}

export default createReducer(initialState, {
  [CHANGE_LOAN_AMOUNT]: (state, payload) => ({
    ...state,
    loanAmount: payload
  }),
  [CHANGE_MONTHLY_PAYMENT]: (state, payload) => ({
    ...state,
    monthlyPayment: payload
  }),
  [CHANGE_PROTECTION_INSURANCE]: (state, payload) => ({
    ...state,
    protectionInsurance: payload
  }),
  [SET_STEP]: (state, payload) => ({
    ...state,
    currentStep: payload
  }),
  [NEXT_STEP]: (state, payload) => ({
    ...state,
    currentStep: payload
  }),
  // FECTH GET_FINANCE
  [FECTH_REQUEST_GET_FINANCE]: (state, payload) => ({
    ...state,
    requestPendingGetFinance: true
  }),
  [FECTH_REQUEST_GET_FINANCE_SUCCESS]: (state, payload) => ({
    ...state,
    result: payload,
    requestPendingGetFinance: false
  }),
  [FECTH_REQUEST_GET_FINANCE_ERROR]: (state, payload) => ({
    ...state,
    errorsMsg: payload,
    requestPendingGetFinance: false
  }),
  // FECTH SEND DATA
  [FECTH_REQUEST_SEND_DATA]: (state, payload) => ({
    ...state,
    requestPendingSendData: true
  }),
  [FECTH_REQUEST_SEND_DATA_SUCCESS]: state => ({
    ...state,
    requestPendingSendData: false
  }),
  [FECTH_REQUEST_SEND_DATA_ERROR]: (state, payload) => ({
    ...state,
    errorsMsg: payload,
    requestPendingSendData: false
  })
})
