import { createReducer } from '../../utils/misc'
import {
  FETCH_PENDING_USER_DOCUMENTS_REQUEST,
  FETCH_PENDING_USER_DOCUMENTS_SUCCESS,
  FETCH_PENDING_USER_DOCUMENTS_ERROR,
  SEND_PENDING_USER_DOCUMENTS_REQUEST,
  SEND_PENDING_USER_DOCUMENTS_SUCCESS,
  SEND_PENDING_USER_DOCUMENTS_ERROR
} from '../constants/user'

const initialState = {
  pendingDocuments: [],
  fetchingPendingDocuments: false,
  fetchPendingDocsError: null,
  sendPendingDocsError: null
}

export default createReducer(initialState, {
  [FETCH_PENDING_USER_DOCUMENTS_REQUEST]: state => ({
    ...state,
    fetchingPendingDocuments: true,
    fetchPendingDocsError: null
  }),
  [FETCH_PENDING_USER_DOCUMENTS_SUCCESS]: (state, payload) => ({
    ...state,
    fetchingPendingDocuments: false,
    pendingDocuments: payload,
    fetchPendingDocsError: null
  }),
  [FETCH_PENDING_USER_DOCUMENTS_ERROR]: state => ({
    ...state,
    fetchingPendingDocuments: false,
    fetchPendingDocsError: 'Hubo un error obteniendo los documentos que le solicitaron, por favor intente nuevamente.'
  }),
  [SEND_PENDING_USER_DOCUMENTS_REQUEST]: state => ({
    ...state,
    fetchingPendingDocuments: true,
    sendPendingDocsError: null
  }),
  [SEND_PENDING_USER_DOCUMENTS_SUCCESS]: state => ({
    ...state,
    pendingDocuments: [],
    fetchingPendingDocuments: false,
    sendPendingDocsError: null
  }),
  [SEND_PENDING_USER_DOCUMENTS_ERROR]: state => ({
    ...state,
    pendingDocuments: [],
    fetchingPendingDocuments: false,
    sendPendingDocsError: 'Hubo un error enviando sus documentos, por favor intente nuevamente.'
  })
})
