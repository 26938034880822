export const CHANGE_LOAN_AMOUNT = 'CHANGE_LOAN_AMOUNT'
export const CHANGE_MONTHLY_PAYMENT = 'CHANGE_MONTHLY_PAYMENT'
export const CHANGE_PROTECTION_INSURANCE = 'CHANGE_PROTECTION_INSURANCE'
export const FECTH_REQUEST_GET_FINANCE = 'FECTH_REQUEST_GET_FINANCE'
export const FECTH_REQUEST_GET_FINANCE_SUCCESS = 'FECTH_REQUEST_GET_FINANCE_SUCCESS'
export const FECTH_REQUEST_GET_FINANCE_ERROR = 'FECTH_REQUEST_GET_FINANCE_ERROR'
export const FECTH_REQUEST_SEND_DATA = 'FECTH_REQUEST_SEND_DATA'
export const FECTH_REQUEST_SEND_DATA_SUCCESS = 'FECTH_REQUEST_SEND_DATA_SUCCESS'
export const FECTH_REQUEST_SEND_DATA_ERROR = 'FECTH_REQUEST_SEND_DATA_ERROR'
export const NEXT_STEP = 'NEXT_STEP'
export const PREVIOUS_STEP = 'PREVIOUS_STEP'
export const SET_STEP = 'SET_STEP'
