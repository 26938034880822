/* eslint-disable global-require */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(LanguageDetector)
  .init({
    fallbackLng: ['es', 'en'],
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    },
    resources: {
      es: {
        translation: require('./languages/es.json')
      },
      en: {
        translation: require('./languages/en.json')
      }
    }
  })

export default i18n
